import axios from "axios";

// CONFIG
import { BASE_API_URL, API_KEY } from "../config";

const axiosInstance = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    apikey: API_KEY,
  },
});

export const getAxiosInstance = () => {
  const setHeaders = (session) => {
    axiosInstance.defaults.headers.common.Authorization = session?.ssoToken;
    axiosInstance.defaults.headers.common.firstName = session?.firstname;
    axiosInstance.defaults.headers.common.lastName = session?.lastname;
    axiosInstance.defaults.headers.common.username = session?.username;
  };

  return { axiosInstance, setHeaders };
};

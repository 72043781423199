// MATERIAL
import { styled } from "@mui/material/styles";
import { ButtonBase, Box as MuiBox } from "@mui/material";

export const Button = styled(ButtonBase)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: 14,
  fontFamily: "inherit",
  padding: 8,
  borderRadius: 8,
  letterSpacing: "0.03em",
}));

export const Box = styled(MuiBox)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

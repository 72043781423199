// REACT
import { useNavigate } from "react-router-dom";

// COMPONENTS
import { Container, Input, Button } from "./styles";
import { SearchIcon } from "../../../../icons/SearchIcon";
import { AddCircleIcon } from "../../../../icons/AddCircleIcon";

export const TableHeader = ({ handleSearch }) => {
  const navigate = useNavigate();

  return (
    <Container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Input
        placeholder="Buscar"
        onChange={handleSearch}
        endAdornment={<SearchIcon sx={{ height: 20 }} />}
      />
      <Button onClick={() => navigate("/workflows/new")}>
        <AddCircleIcon />
        Crear Nuevo
      </Button>
    </Container>
  );
};

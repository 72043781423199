// REACT
import { useParams } from "react-router-dom";

// COMPONENTS
import { EventForm } from "../../../components/pages/EventForm";
import { Loading } from "../../../components/common/Loading";

// HOOKS
import { useGetEvent } from "../../../hooks/Events/useGetEvent";

export const EditEvent = () => {
  const { id } = useParams();

  const { data, isLoading } = useGetEvent(id);

  if (isLoading) return <Loading />;

  return <EventForm {...{ id, defaultValues: data }} />;
};

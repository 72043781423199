// REACT
import { useQueryClient, useMutation } from "react-query";

// SERVICES
import { getAxiosInstance } from "../../services/axiosConfig";

const deleteEventById = async (oldEvent) => {
  const { axiosInstance } = getAxiosInstance();

  const { data } = await axiosInstance.delete(`/events/${oldEvent._id}`);

  return data;
};

export const useDeleteEvent = () => {
  const queryClient = useQueryClient();

  const deleteUserMutation = useMutation(deleteEventById, {
    // When mutate is called:
    onMutate: async (oldEvent) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(["events"]);

      // Snapshot the previous value
      const previousQueryData = queryClient.getQueryData(["events"]);

      const newQueryData = previousQueryData.filter(
        (event) => event._id !== oldEvent._id
      );

      // Optimistically update to the new value
      queryClient.setQueryData(["events"], newQueryData);

      // Return a context object with the snapshotted values
      return { oldEvent, previousQueryData };
    },

    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (error, variables, { previousQueryData }) => {
      queryClient.setQueryData(["events"], previousQueryData);
    },

    // Always refetch after error or success:
    onSettled: (data, error, variables, { oldEvent }) => {
      queryClient.invalidateQueries(["events"]);
    },

    // Do something if query succeed
    onSuccess: (data, variables, context) => {},
  });

  return deleteUserMutation;
};

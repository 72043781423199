// REACT
import PropTypes from "prop-types";

// COMPONENTS
import { AutocompletePopper } from "./styles";

export const PopperComponent = ({
  disablePortal,
  anchorEl,
  open,
  ...other
}) => {
  return <AutocompletePopper {...other} />;
};

PopperComponent.propTypes = {
  anchorEl: PropTypes.any,
  disablePortal: PropTypes.bool,
  open: PropTypes.bool.isRequired,
};

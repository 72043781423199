// REACT
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// MATERIAL
import { Stack, TableBody, TableRow, Chip } from "@mui/material";

// COMPONENTS
import { useTable } from "../../../common/Table";
import { TableCell, ActionButton } from "../../../common/Table/styles";
import { CircleIcon } from "../../../../components/icons/CircleIcon";
import { EditIcon } from "../../../icons/EditIcon";
import { DeleteIcon } from "../../../icons/DeleteIcon";
import { ConfirmDialog } from "../../../common/ConfirmDialog";

// HOOKS
import { useDeleteEvent } from "../../../../hooks/Events/useDeleteEvent";

export const EventListTable = ({ data, headCells, filterFn }) => {
  const [dialogState, setDialogState] = useState({
    event: null,
    isOpen: false,
  });

  const { Table, TableHead, TablePagination, recordsAfterPagingAndSorting } =
    useTable(data, headCells, filterFn);

  const navigate = useNavigate();

  const { mutate } = useDeleteEvent();

  const handleOpenDialog = (event) => setDialogState({ event, isOpen: true });

  const handleCloseDialog = () =>
    setDialogState({ event: null, isOpen: false });

  const handleAgree = () => {
    mutate(dialogState.event);
    handleCloseDialog();
  };

  return (
    <>
      <Table>
        <TableHead />
        <TableBody>
          {recordsAfterPagingAndSorting()?.map((row) => (
            <TableRow key={row._id}>
              <TableCell>{row.code}</TableCell>
              <TableCell>
                <Chip
                  size="small"
                  label={row.enabled ? "Activo" : "Inactivo"}
                  color={row.enabled ? "success" : "primary"}
                  icon={<CircleIcon />}
                />
              </TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell>
                <Stack direction="row" spacing={1}>
                  <ActionButton onClick={() => navigate(`/events/${row._id}`)}>
                    <EditIcon />
                  </ActionButton>

                  <ActionButton onClick={() => handleOpenDialog(row)}>
                    <DeleteIcon />
                  </ActionButton>
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination />

      <ConfirmDialog
        isOpen={dialogState.isOpen}
        handleAgree={handleAgree}
        handleDismiss={handleCloseDialog}
        title="Evento"
        content="¿Estás seguro de que quieres eliminar este evento?"
      />
    </>
  );
};

// REACT
import { useContext, useState, useEffect } from "react";

// MATERIAL
import { Dialog, DialogContent, Typography, IconButton } from "@mui/material";

// COMPONENTS
import { CloseIcon } from "../../icons/CloseIcon";

// CONTEXT
import { AlertContext } from "../../../contexts/Alerts/Alerts";

// ASSETS
import wowIcon from "../../../assets/images/wow.png";

export const TimedAlert = () => {
  const [state] = useContext(AlertContext);

  const [open, setOpen] = useState(false);
  const [alertType, setAlertType] = useState("NONE");
  const [message, setMessage] = useState("Some Message");
  const [title, setTitle] = useState("Some title");

  useEffect(() => {
    const { message, type, title } = state.alertShower;
    setAlertType(type);
    setTitle(title);
    setMessage(message);
    if (type !== "NONE") setOpen(true);
  }, [state.alertShower]);

  useEffect(() => {
    let timer;
    if (open) {
      timer = setTimeout(() => {
        setOpen(false);
      }, 3000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [open]);

  const handleClose = () => setOpen(false);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      BackdropProps={{
        style: { backgroundColor: "hsla(226, 100%, 60%, 0.56)" },
      }}
      PaperProps={{
        style: {
          position: "relative",
          borderRadius: "15px",
          minWidth: 460,
          maxWidth: 500,
          width: "100%",
        },
      }}
    >
      <IconButton
        aria-label="close"
        sx={{ position: "absolute", right: 8, top: 8 }}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "15px",
        }}
      >
        {alertType === "SUCCESS" ? (
          <img src={wowIcon} alt="Congratulations Icon" />
        ) : null}
        <Typography variant="h3" sx={{ fontSize: 50, fontWeight: 700 }}>
          {title}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ fontSize: 16, fontWeight: 700, textAlign: "center" }}
        >
          {message}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

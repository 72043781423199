// REACT
import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

// MATERIAL
import { Stack, CircularProgress, Divider } from "@mui/material";

// COMPONENTS
import { PageContainer } from "../../common/PageContainer";
import { BackButton } from "../../common/BackButton";
import { PageTitle } from "../../common/PageTitle";
import { FormSubmitButton } from "../../common/FormSubmitButton";
import { Paper } from "../../common/Paper";
import { Form } from "./Form";
import { Params } from "./Params";

// CONTEXTS
import { AlertContext } from "../../../contexts/Alerts/Alerts";

// HOOKS
import { useSaveEvent } from "../../../hooks/Events/useSaveEvent";

// HELPERS
import TimedAlertActions from "../../common/TimedAlert/TimedAlert.actions";

const defaultFormValues = {
  enabled: true,
  code: "",
  description: "",
  params: [],
};

export const EventForm = ({ id, defaultValues = defaultFormValues }) => {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [_state, dispatch] = useContext(AlertContext);

  const onSuccess = () => {
    navigate("/events");
    dispatch(
      TimedAlertActions.showSuccessAlert({
        title: "¡Felicidades!",
        message: id
          ? "Te acabas de guardar este evento"
          : "Te acabas de guardar un nuevo evento",
      })
    );
  };

  const { mutate, isLoading } = useSaveEvent({ onSuccess });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isValid, isDirty },
  } = useForm({
    defaultValues,
    mode: "onBlur",
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = (data) => {
    mutate({ id, ...data });
  };

  return (
    <PageContainer>
      <PageTitle>Registro de Eventos</PageTitle>

      <BackButton label="Volver atrás" onClick={() => navigate("/events")} />

      <Stack component="form" spacing={2} onSubmit={handleSubmit(onSubmit)}>
        <Paper sx={{ py: 3, px: 2 }}>
          <Form {...{ control, watch }} />

          <Divider sx={{ my: 4 }} />

          <Params {...{ control, watch }} />
        </Paper>

        <FormSubmitButton
          type="submit"
          disabled={!isDirty || !isValid || isLoading}
        >
          {isLoading ? (
            <CircularProgress size={30} color="inherit" />
          ) : (
            "Guardar"
          )}
        </FormSubmitButton>
      </Stack>
    </PageContainer>
  );
};

// MATERIAL
import { switchClasses, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  [`& .${switchClasses.track}`]: {
    borderRadius: 22 / 2,
  },
  [`& .${switchClasses.thumb}`]: {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
    backgroundColor: "white",
  },
  [`.${switchClasses.checked}`]: {
    [`& + .${switchClasses.track}`]: {
      opacity: "1 !important",
    },
  },
}));

// MATERIAL
import { Button as MuiButton } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Button = styled(MuiButton)(({ theme }) => ({
  minHeight: 48,
  maxWidth: 177,
  width: "100%",
  borderRadius: 16,

  fontSize: 18,
  fontWeight: 700,
  textTransform: "none",

  marginLeft: "auto !important",
}));

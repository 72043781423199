// REACT
import { useQuery } from "react-query";

// SERVICES
import { getAxiosInstance } from "../../services/axiosConfig";

const getAllServices = async () => {
  const { axiosInstance } = getAxiosInstance();

  const {
    data: { data },
  } = await axiosInstance.get("/services");

  return data;
};

export const useGetServices = () =>
  useQuery(["services"], getAllServices, {
    staleTime: 1000 * 60 * 10, // 10 minutes after data is considered stale. If the data is fresh it will be returned from the cache
  });

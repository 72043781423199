// MATERIAL
import { InputBase } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Header = styled("div")({
  padding: "25px 25px 17px",
});

export const Ul = styled("ul")({
  listStyleType: "none",
  padding: 0,
  margin: 0,
});

export const Li = styled("li")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: 46,
  padding: "16px 25px 16px",
  fontWeight: 600,
  borderTop: "0.6px solid hsla(0, 0%, 96%, 1)",
});

export const Input = styled(InputBase)(({ theme }) => ({
  padding: "4px 16px",
  borderRadius: 16,
  border: `1px solid ${theme.palette.lightSky[200]}}`,
}));

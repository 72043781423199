import TimedAlertActions from "./TimedAlert.actions";

const TimedAlertReducer = (state, action) => {
  switch (action.type) {
    case TimedAlertActions.SHOW_ALERT:
      return {
        ...state,
        alertShower: action.payload,
      };
    default:
      return state;
  }
};

export default TimedAlertReducer;

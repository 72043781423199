// COMPONENTS
import { Button } from "./styles";
import { BellIcon } from "../../../icons/BellIcon";

export const NotificationsButton = ({ ...props }) => {
  return (
    <Button {...props}>
      <BellIcon />
    </Button>
  );
};

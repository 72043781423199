// MATERIAL
import { Toolbar as MuiToolbar } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Toolbar = styled(MuiToolbar)({
  justifyContent: "flex-end",
  minHeight: "72px !important",
  "& button:not(:first-of-type)": {
    marginLeft: 10,
  },
});

// REACT
import { useState } from "react";

// MATERIAL
import {
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  TablePagination as MuiTablePagination,
  TableSortLabel as MuiTableSortLabel,
} from "@mui/material";

// COMPONENTS
import { TableCell } from "./styles";

export const useTable = (
  records,
  headCells,
  filterFn = { fn: (filter) => filter }
) => {
  const pages = [5, 10, 25];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("");

  const Table = ({ children }) => <MuiTable>{children}</MuiTable>;

  const TableHead = () => {
    const handleSortRequest = (cellId) => {
      const isAsc = orderBy === cellId && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(cellId);
    };

    return (
      <MuiTableHead>
        <MuiTableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.disableSorting ? (
                headCell.label
              ) : (
                <MuiTableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={() => {
                    handleSortRequest(headCell.id);
                  }}
                >
                  {headCell.label}
                </MuiTableSortLabel>
              )}
            </TableCell>
          ))}
        </MuiTableRow>
      </MuiTableHead>
    );
  };

  const TablePagination = () => (
    <MuiTablePagination
      rowsPerPageOptions={pages}
      component="div"
      count={records?.length || 0}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelRowsPerPage={<span>Filas por página:</span>}
      labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
      showFirstButton={true}
      showLastButton={true}
      backIconButtonProps={{ color: "secondary" }}
      nextIconButtonProps={{ color: "secondary" }}
    />
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const recordsAfterPagingAndSorting = () => {
    return stableSort(
      filterFn.fn(records),
      getComparator(order, orderBy)
    )?.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  };

  return {
    Table,
    TableHead,
    TablePagination,
    setPage,
    recordsAfterPagingAndSorting,
  };
};

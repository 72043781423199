// REACT
import { useMutation, useQueryClient } from "react-query";

// SERVICES
import { getAxiosInstance } from "../../services/axiosConfig";

const saveWorkflow = async ({ _id: id, ...form }) => {
  const { axiosInstance } = getAxiosInstance();

  if (id) {
    const {
      data: { data },
    } = await axiosInstance.put(`/workflows/${id}`, { ...form });

    return data;
  } else {
    const {
      data: { data },
    } = await axiosInstance.post("/workflows", {
      ...form,
    });

    return data;
  }
};

export const useSaveWorkflow = ({ onSuccess }) => {
  const queryClient = useQueryClient();

  const saveFlowMutation = useMutation(saveWorkflow, {
    // When mutate is called:
    onMutate: async (workflow) => {
      await queryClient.cancelQueries(["workflows"]);

      // Snapshot previous query data
      const previousQueryData = queryClient.getQueryData(["workflows"]);
      const action = workflow?._id ? "UPDATE" : "INSERT";

      return { previousQueryData, action };
    },

    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (error, variables, context) => {
      queryClient.setQueryData(["workflows"], context.previousQueryData);
    },

    // Always refetch after error or success:
    onSettled: (newWorkflow, error, variables, context) => {
      queryClient.invalidateQueries(["workflows"]);
    },

    // Do something if query succeed
    onSuccess: (newWorkflow, variables, context) => {
      if (onSuccess) onSuccess();
    },
  });

  return saveFlowMutation;
};

// MATERIAL
import { SvgIcon } from "@mui/material";

export const BellIcon = ({ sx, ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ height: 24, ...sx }} {...rest}>
      <path
        d="M23.1644 19.6236L20.4284 14.7351V9.76942C20.4284 4.66844 16.5116 0.523895 11.6924 0.523895C6.87319 0.523895 2.95639 4.66844 2.95639 9.76942V15.2085L0.959595 19.6236H7.37239C7.52599 22.0678 9.54199 24 11.9996 24C14.4572 24 16.4732 22.0581 16.6268 19.6236H23.1644ZM4.44439 15.5273V9.76942C4.44439 5.49928 7.69879 2.031 11.6924 2.031C15.686 2.031 18.9404 5.50894 18.9404 9.76942V15.1312L20.6108 18.1261H3.27319L4.44439 15.5273ZM11.9996 22.5025C10.358 22.5025 9.01399 21.237 8.86039 19.6236H15.1388C14.9948 21.237 13.6412 22.5025 11.9996 22.5025Z"
        fill="white"
      />
    </SvgIcon>
  );
};

import { SvgIcon } from "@mui/material";

export const DeleteIcon = ({ sx, ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 18 18" sx={{ height: 18, ...sx }} {...rest}>
      <path
        d="M16.6622 2.72525H11.9672V0.579208C11.9672 0.259901 11.7035 0 11.3794 0H5.86304C5.53899 0 5.28277 0.259901 5.28277 0.579208V2.72525H0.587811C0.263761 2.72525 0 2.97772 0 3.29703C0 3.61634 0.263761 3.87624 0.587811 3.87624H1.83879V15.0223C1.83879 16.6634 3.19528 18 4.86075 18H12.3893C14.0547 18 15.4112 16.6634 15.4112 15.0223V3.87624H16.6622C16.9862 3.87624 17.25 3.61634 17.25 3.29703C17.25 2.97772 16.9862 2.72525 16.6622 2.72525ZM6.45085 1.15099H10.8067V2.72525H6.45085V1.15099ZM14.2431 15.0223C14.2431 16.0322 13.4066 16.849 12.3893 16.849H4.86075C3.83585 16.849 3.00688 16.0248 3.00688 15.0223V3.87624H14.2431V15.0223Z"
        fill="#212121"
      />
      <path
        d="M5.86304 4.84158C5.53899 4.84158 5.27523 5.10149 5.27523 5.42079V15.1559C5.27523 15.4752 5.53899 15.7351 5.86304 15.7351C6.18709 15.7351 6.45085 15.4752 6.45085 15.1559V5.41337C6.45085 5.09406 6.18709 4.84158 5.86304 4.84158Z"
        fill="#212121"
      />
      <path
        d="M10.7991 5.41337V15.1485C10.7991 15.4678 11.0629 15.7277 11.387 15.7277C11.711 15.7277 11.9748 15.4678 11.9748 15.1485V5.41337C11.9748 5.09406 11.711 4.83416 11.387 4.83416C11.0629 4.84158 10.7991 5.09406 10.7991 5.41337Z"
        fill="#212121"
      />
      <path
        d="M8.62123 4.84158C8.29718 4.84158 8.03342 5.10149 8.03342 5.42079V15.1559C8.03342 15.4752 8.29718 15.7351 8.62123 15.7351C8.94528 15.7351 9.20904 15.4752 9.20904 15.1559V5.41337C9.20904 5.09406 8.94528 4.84158 8.62123 4.84158Z"
        fill="#212121"
      />
    </SvgIcon>
  );
};

// MATERIAL
import { IconButton, MenuItem } from "@mui/material";

// COMPONENTS
import { Menu } from "./styles";
import { MoreVertIcon } from "../../../../icons/MoreVertIcon";
import { AddIcon } from "../../../../icons/AddIcon";
import { DeleteIcon } from "../../../../icons/DeleteIcon";
import { PencilIcon } from "../../../../icons/PencilIcon";

export const StepActions = ({
  handleOpenMenu,
  anchorEl,
  open,
  handleCloseMenu,
  handleDeleteStep,
  handleFocusStep,
  handleAddNestedStep,
  index,
}) => {
  return (
    <>
      <IconButton onClick={handleOpenMenu} sx={{ ml: "auto" }}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="step-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        elevation={0}
        MenuListProps={{
          "aria-labelledby": "step-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {handleDeleteStep && (
          <MenuItem onClick={handleDeleteStep}>
            <DeleteIcon />
          </MenuItem>
        )}

        {handleFocusStep && (
          <MenuItem onClick={handleFocusStep}>
            <PencilIcon />
          </MenuItem>
        )}

        {handleAddNestedStep && (
          <MenuItem onClick={handleAddNestedStep}>
            <AddIcon />
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

// MATERIAL
import { Container as MuiContainer } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Main = styled("main")(({ theme }) => ({
  flexGrow: 1,
  paddingTop: theme.spacing(9),
  height: "100vh",
}));

export const Container = styled(MuiContainer)({
  height: "100%",
});

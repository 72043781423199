// COMPONENTS
import { IconButton } from "@mui/material";
import { Paper } from "../../../../common/Paper";
import { DeleteIcon } from "../../../../icons/DeleteIcon";
import { Header, Li, Ul, Input } from "./styles";
import { SearchIcon } from "../../../../icons/SearchIcon";

export const ClientsList = ({
  filteredValues,
  handleFilter,
  handleDeleteClient,
}) => {
  return (
    <Paper elevation={3} noPadding>
      <Header>
        <Input
          placeholder="Escriba un nombre de cliente"
          endAdornment={<SearchIcon />}
          fullWidth
          onChange={handleFilter}
        />
      </Header>
      <Ul>
        {filteredValues().map((value) => (
          <Li key={value.rut}>
            {value.name}
            <IconButton onClick={() => handleDeleteClient(value.rut)}>
              <DeleteIcon />
            </IconButton>
          </Li>
        ))}
      </Ul>
    </Paper>
  );
};

import { styled, Button as MuiButton } from "@mui/material";

export const Pre = styled("pre")(({ theme }) => ({
  padding: "16px",
  backgroundColor: "hsla(0, 0%, 98%, 1)",
  border: "0.6px solid hsla(225, 100%, 92%, 1)",
  borderRadius: 10,
  whiteSpace: "pre-wrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
  "& h6": {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 900,
    fontStyle: "normal",
    margin: "20px 0",
  },
}));

export const Button = styled(MuiButton)({
  borderRadius: 12,
  textTransform: "none",
  minWidth: 109,
  padding: "9px 16px",
});

export const Ul = styled("ul")({
  margin: 0,
  padding: 0,
});

export const Li = styled("li")({
  listStyleType: "none",
  borderTop: "0.6px solid hsla(0, 0%, 96%, 1)",
  padding: "10px 15px",

  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

// MATERIAL
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const PaperTitle = styled((props) => (
  <Typography variant="h5" component="h2" {...props} />
))({
  fontSize: 18,
  fontWeight: 700,
});

// REACT
import { Routes, Route, Navigate } from "react-router-dom";

// UNIVERSAL
import withUniversalSession, {
  useSession,
} from "@blue-express/bx-lib-universal-frontend";

// PAGES
import { Navigation } from "./components/layout/Navigation";
import { TrackingList } from "./pages/TrackingList";
import { CreateTracking } from "./pages/TrackingConfig/Create";
import { EditTracking } from "./pages/TrackingConfig/Edit";
import { EventsList } from "./pages/EventList";
import { CreateEvent } from "./pages/EventConfig/Create";
import { EditEvent } from "./pages/EventConfig/Edit";

// HOOKS
import { getAxiosInstance } from "./services/axiosConfig";

function App() {
  const { getSession } = useSession();
  const session = getSession();

  const { setHeaders } = getAxiosInstance();

  setHeaders(session);

  return (
    session && (
      <Routes>
        <Route path="/" element={<Navigation />}>
          <Route index element={<Navigate to="/workflows" />} />
          <Route path="workflows" element={<TrackingList />} />
          <Route path="workflows/new" element={<CreateTracking />} />
          <Route path="workflows/:id" element={<EditTracking />} />
          <Route path="events" element={<EventsList />} />
          <Route path="events/new" element={<CreateEvent />} />
          <Route path="events/:id" element={<EditEvent />} />
        </Route>
      </Routes>
    )
  );
}

export default withUniversalSession(App, {
  env: "dev",
  service: "tracking",
  storage: "localStorage",
  locale: "es",
});

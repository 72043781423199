// REACT
import { useFieldArray } from "react-hook-form";

// MATERIAL
import { Grid } from "@mui/material";

// COMPONENTS
import { Paper } from "../../../common/Paper";
import { Button, Box } from "./styles";
import { AddCircleIcon } from "../../../icons/AddCircleIcon";
import { Step1 } from "./Step1";

export const Steps = ({ control }) => {
  const {
    fields: fields1,
    append: append1,
    remove: remove1,
  } = useFieldArray({ control, name: "steps" });

  const AppendButton = (name) => (
    <Button
      onClick={() => append1({ name: `Paso ${fields1.length + 1}`, steps: [] })}
    >
      <AddCircleIcon />
      Agregar nuevo Paso
    </Button>
  );

  return (
    <Paper sx={{ py: 4, px: 3 }}>
      {fields1.length === 0 ? (
        <Box>
          <AppendButton />
        </Box>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={9} justifyContent="center" />

          <Grid
            item
            xs={3}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <AppendButton />
          </Grid>
          <Grid item xs={12}>
            {fields1.map((item1, index1) => (
              <Step1
                {...{
                  key: item1.id,
                  item1,
                  index1,
                  control,
                  remove1,
                }}
              />
            ))}
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

// REACT
import { useState } from "react";
import { NavLink } from "react-router-dom";

// MATERIAL
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";

// COMPONENTS
import { AppDrawer, Header, DrawerToggleButton, Img } from "./styles";
import { TruckIcon } from "../../icons/TruckIcon";
import { LeftChevronIcon } from "../../icons/LeftChevronIcon";
import { ExpandLess } from "../../icons/ExpandLess";
import { ExpandMore } from "../../icons/ExpandMore";

// ASSETS
import LogoBlue from "../../../assets/images/blue-logo.jpg";

export const Drawer = ({ open, handleToggleDrawer, drawerWidth }) => {
  const [openEnvios, setOpenEnvios] = useState(true);

  const handleClickEnvios = () => {
    if (!open) handleToggleDrawer();
    setOpenEnvios(!openEnvios);
  };

  return (
    <AppDrawer
      {...{
        variant: "permanent",
        anchor: "left",
        as: "nav",
        open,
        drawerWidth,
      }}
    >
      <Header>
        <Img src={LogoBlue} alt="Logo Blue" />
      </Header>

      <DrawerToggleButton onClick={handleToggleDrawer} {...{ open }}>
        <LeftChevronIcon />
      </DrawerToggleButton>

      <List>
        {/* <ListItem button component={Link} to="/workflows">
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem> */}

        <ListItem button onClick={handleClickEnvios}>
          <ListItemIcon>
            <TruckIcon />
          </ListItemIcon>
          <ListItemText primary="Fast tracking" />
          {openEnvios ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openEnvios && open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={NavLink} to="/workflows">
              <ListItemText primary="Panel Seguimiento" />
            </ListItem>
            <ListItem button component={NavLink} to="/events">
              <ListItemText primary="Registro de Eventos" />
            </ListItem>
          </List>
        </Collapse>
      </List>
    </AppDrawer>
  );
};

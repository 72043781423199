// MATERIAL
import { Popper as MuiPopper, autocompleteClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

export const AutocompletePopper = styled("div")(({ theme }) => ({
  width: "400px !important",
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    margin: 0,
    color: "inherit",
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128",
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: "auto",
      alignItems: "flex-start",
      padding: 8,
      borderBottom: `1px solid  ${
        theme.palette.mode === "light" ? " #eaecef" : "#30363d"
      }`,
      '&[aria-selected="true"]': {
        backgroundColor: "transparent",
      },
      '&[data-focus="true"], &[data-focus="true"][aria-selected="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: "relative",
  },
}));

export const Popper = styled(MuiPopper)(({ theme }) => ({
  boxShadow:
    "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
  borderRadius: 15,
  width: 400,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128",
}));

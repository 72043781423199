// COMPONENTS
import { Button } from "./styles";
import { SquaresIcon } from "../../../icons/SquaresIcon";

export const SquaresButton = ({ ...props }) => {
  return (
    <Button {...props}>
      <SquaresIcon />
    </Button>
  );
};

// REACT
import { createContext, useReducer } from "react";

import reducer from "../../components/common/TimedAlert/TimedAlert.reducer";

const initialState = {
  alertShower: {
    type: "NONE",
    message: "",
  },
};

export const AlertContext = createContext(initialState);

export const AlertProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = [state, dispatch];

  return (
    <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
  );
};

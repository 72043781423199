// REACT
import { useState, useEffect } from "react";
import { Controller } from "react-hook-form";

// MATERIAL
import { Grid, Stack, InputLabel, Typography } from "@mui/material";

// COMPONENTS
import { Paper } from "../../../common/Paper";
import { PaperTitle } from "../../../common/PaperTitle";
import { HighlightBox } from "../../../common/HighlightBox";
import { SwitchButton } from "../../../common/SwitchButton";
import { TextField } from "../../../common/TextField";
import { RadioGroup } from "./RadioGroup";
import { ComboBox } from "./ComboBox";
import { ClientsList } from "./ClientsList";
import { ServiceSelect } from "./ServiceSelect";

export const Form = ({ control, watch, setValue, clients, services = [] }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilter] = useState("");
  const [selectedClients, setSelectedClients] = useState([]);
  const [pendingClients, setPendingClients] = useState([]);

  useEffect(() => {
    setSelectedClients(clients);
  }, [clients]);

  const handleClick = (event) => {
    setPendingClients(selectedClients);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSelectedClients(pendingClients);
    if (anchorEl) anchorEl.focus();
    setAnchorEl(null);
  };

  const handleFilter = (event) => {
    const { value } = event.target;
    setFilter(value);
  };

  const handleDeleteClient = (rut) => {
    setSelectedClients((old) => {
      const newSelectedClients = old.filter((item) => item.rut !== rut);
      setValue("clients", newSelectedClients);
      return newSelectedClients;
    });
    setPendingClients((old) => old.filter((item) => item.rut !== rut));
  };

  const filteredValues = () =>
    selectedClients.filter((item) => item.name.toLowerCase().includes(filter));

  const open = Boolean(anchorEl);
  const id = open ? "clients-combobox" : undefined;

  const showCustomFiels = watch("typeFlow") === "Personalizado";

  return (
    <Paper sx={{ py: 3, px: 2 }}>
      <Grid container spacing={4}>
        <Grid item xs={9}>
          <PaperTitle>Crea un nuevo flujo de tracking</PaperTitle>
          <Typography variant="subtitle1">
            Crea un paso a paso de su tracking forma rápida y sencilla.
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <HighlightBox centered padding={4}>
            <SwitchButton
              {...{
                control,
                name: "enabled",
                label: "Activar el flujo",
              }}
            />
          </HighlightBox>
        </Grid>

        <Grid item xs={5}>
          <InputLabel>Código el flujo</InputLabel>
          <Controller
            control={control}
            name="code"
            rules={{
              required: "Campo obligatorio",
              pattern: {
                value: /^(\S+$)/,
                message: "El código no puede tener espacios",
              },
            }}
            render={({ field: { value, ...rest }, fieldState: { error } }) => (
              <TextField
                fullWidth
                required
                value={value?.toUpperCase()}
                error={Boolean(error)}
                helperText={error?.message}
                placeholder="Ingresse un código del flujo"
                {...rest}
              />
            )}
          />
        </Grid>

        <Grid item xs={5}>
          <InputLabel>Tipo del servicio</InputLabel>
          <ServiceSelect {...{ control, services }} />
        </Grid>

        <Grid item xs={10}>
          <InputLabel>Descripción</InputLabel>

          <Controller
            control={control}
            name="description"
            rules={{ required: "Campo obligatorio" }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                required
                error={Boolean(error)}
                helperText={error?.message}
                placeholder="Inscriba una descripción"
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Stack spacing={2}>
                <RadioGroup {...{ control }} />

                {showCustomFiels && (
                  <HighlightBox sx={{ py: 3, px: 4 }}>
                    <ComboBox
                      {...{
                        control,
                        id,
                        handleClick,
                        open,
                        selectedClients,
                        anchorEl,
                        handleClose,
                        setPendingClients,
                      }}
                    />
                  </HighlightBox>
                )}
              </Stack>
            </Grid>

            {showCustomFiels && selectedClients.length > 0 && (
              <Grid item xs={6}>
                <ClientsList
                  {...{
                    filteredValues,
                    handleFilter,
                    handleDeleteClient,
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

// MATERIAL
import { TextField as MuiTextField, inputBaseClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

export const TextField = styled(MuiTextField)(({ theme }) => ({
  marginTop: theme.spacing(1),
  [`.${inputBaseClasses.root}`]: {
    borderRadius: 16,
    height: 40,
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
    fieldset: {
      borderColor: theme.palette.lightSky[200],
    },
  },
}));

import { SvgIcon } from "@mui/material";

export const EditIcon = ({ sx, ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 18 18" sx={{ height: 18, ...sx }} {...rest}>
      <path
        d="M15.1438 0.0621493C15.966 0.0995229 16.7434 0.473259 17.2815 1.10113C18.2906 2.26719 18.2308 4.04617 17.1395 5.16738L10.5917 11.8348C10.4347 11.9993 10.2254 12.1114 10.0012 12.1562L6.48806 12.8962C6.41331 12.9112 6.33109 12.9187 6.24886 12.9187C5.94988 12.9187 5.65836 12.7991 5.4416 12.5898C5.16503 12.3207 5.03049 11.932 5.09776 11.5508L5.62846 8.40393C5.67331 8.15727 5.78543 7.93302 5.96483 7.76111L12.9238 0.914267C13.5143 0.338714 14.329 0.0322505 15.1438 0.0621493ZM9.76198 10.9678L16.3024 4.30779C16.9452 3.65001 16.9826 2.58113 16.3696 1.87851C16.0407 1.49729 15.5848 1.28053 15.084 1.2581H15.0092C14.5383 1.2581 14.0898 1.4375 13.7535 1.77386L6.79452 8.61322L6.27876 11.7078L9.76198 10.9678ZM13.8955 16.6485C14.2692 16.6485 14.5757 16.342 14.5757 15.9683V9.00187H15.7716V15.9683C15.7716 16.9998 14.927 17.8445 13.8955 17.8445H1.87615C0.844643 17.8445 0 16.9998 0 15.9683V3.94897C0 2.91745 0.844643 2.07281 1.87615 2.07281H9.35834V3.26877H1.87615C1.50242 3.26877 1.19595 3.57523 1.19595 3.94897V15.9683C1.19595 16.342 1.50242 16.6485 1.87615 16.6485H13.8955Z"
        fill="#212121"
      />
    </SvgIcon>
  );
};

// REACT
import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

// MATERIAL
import { Stack, CircularProgress } from "@mui/material";

// COMPONENTS
import { PageContainer } from "../../common/PageContainer";
import { PageTitle } from "../../common/PageTitle";
import { BackButton } from "../../common/BackButton";
import { Form } from "./Form";
import { Steps } from "./Steps";
import { FormSubmitButton } from "../../common/FormSubmitButton";

// CONTEXT
import { AlertContext } from "../../../contexts/Alerts/Alerts";

// HOOKS
import { useSaveWorkflow } from "../../../hooks/Workflows/useSaveWorkflow";

// HELPERS
import TimedAlertActions from "../../common/TimedAlert/TimedAlert.actions";

const defaultFormValues = {
  versionFlow: 1,
  code: "",
  description: "",
  enabled: true,
  serviceId: null,
  typeFlow: "Estandar",
  steps: [],
  clients: [],
  fields: [],
};

export const TrackingForm = ({ id, defaultValues = defaultFormValues }) => {
  // eslint-disable-next-line no-unused-vars
  const [_state, dispatch] = useContext(AlertContext);
  const navigate = useNavigate();

  const onSuccess = () => {
    navigate("/workflows");
    dispatch(
      TimedAlertActions.showSuccessAlert({
        title: "¡Felicidades!",
        message: id
          ? "Te acabas de guardar  este flujo tracking"
          : "Te acabas de guardar  un nuevo flujo tracking",
      })
    );
  };

  const { mutate, isLoading } = useSaveWorkflow({ onSuccess });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { isValid, isDirty },
  } = useForm({ defaultValues, mode: "onBlur" });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = (data) => {
    const { clients, fields, ...rest } = data;

    mutate({
      _id: id,
      workflows: { ...rest },
      fields: [...fields],
      clients: [...clients],
    });
  };

  return (
    <PageContainer>
      <PageTitle>Configuración Flujo Tracking</PageTitle>

      <BackButton label="Volver atrás" onClick={() => navigate("/workflows")} />

      <Stack component="form" spacing={2} onSubmit={handleSubmit(onSubmit)}>
        <Form
          {...{ control, watch, setValue, clients: defaultValues?.clients }}
        />

        <Steps {...{ control }} />

        <FormSubmitButton
          type="submit"
          disabled={!isDirty || !isValid || isLoading}
        >
          {isLoading ? (
            <CircularProgress size={30} color="inherit" />
          ) : (
            "Guardar"
          )}
        </FormSubmitButton>
      </Stack>
    </PageContainer>
  );
};

// REACT
import { useState } from "react";

// COMPONENTS
import { PageContainer } from "../../components/common/PageContainer";
import { PageTitle } from "../../components/common/PageTitle";
import { Paper } from "../../components/common/Paper";
import { TableHeader } from "../../components/pages/EventsList/Table/TableHeader";
import { EventListTable } from "../../components/pages/EventsList/Table";
import { Loading } from "../../components/common/Loading";

// HOOKS
import { useGetEvents } from "../../hooks/Events/useGetEvents";

const headCells = [
  { id: "code", label: "Cód. Evento" },
  { id: "status", label: "Status" },
  { id: "description", label: "Descripción" },
  { id: "actions", label: "Acciones", disableSorting: true },
];

export const EventsList = () => {
  const { data, isLoading } = useGetEvents();

  const [filterFn, setFilterFn] = useState({
    fn: (events) => {
      return events;
    },
  });

  const handleSearch = (event) => {
    const { value } = event.target;

    setFilterFn({
      fn: (events) => {
        if (value === "") return events;
        else
          return events.filter(
            (event) =>
              event.code.toLowerCase().includes(value.toLowerCase()) ||
              event.description.toLowerCase().includes(value.toLowerCase())
          );
      },
    });
  };

  if (isLoading) return <Loading />;

  return (
    <PageContainer spacing={2}>
      <PageTitle>Listado de Eventos</PageTitle>

      <Paper elevation={3} noPadding>
        <TableHeader {...{ handleSearch }} />
        <EventListTable {...{ data, headCells, filterFn }} />
      </Paper>
    </PageContainer>
  );
};

// REACT
import { useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";

// MATERIAL
import { InputBase, AccordionSummary, AccordionDetails } from "@mui/material";

// COMPONENTS
import { Accordion } from "./styles";
import { StepActions } from "../StepActions";
import { ExpandIcon } from "../../../../icons/ExpandIcon";
import { Step4 } from "../Step4";

export const Step3 = ({ item3, index1, index2, index3, control, remove3 }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const open = Boolean(anchorEl);

  const {
    fields: fields4,
    append: append4,
    remove: remove4,
  } = useFieldArray({
    control,
    name: `steps[${index1}].steps[${index2}].steps[${index3}].steps`,
  });

  const handleOpenMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (event) => {
    if (expanded) event?.stopPropagation();
    setAnchorEl(null);
  };

  const handleDeleteStep = (event) => {
    handleCloseMenu(event);
    remove3(index3);
  };

  const handleAddNestedStep = (event) => {
    handleCloseMenu(event);
    append4({
      name: `Paso ${index1 + 1}.${index2 + 1}.${index3 + 1}.${
        fields4?.length + 1
      }`,
      steps: [],
    });
  };

  const onInputClick = (event) => {
    event.stopPropagation();
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion
      expanded={expanded === "panel3"}
      onChange={handleChange("panel3")}
      elevation={0}
    >
      <AccordionSummary
        expandIcon={<ExpandIcon />}
        aria-controls="panel3a-content"
        id="panel3a-header"
      >
        <Controller
          name={`steps[${index1}].steps[${index2}].steps[${index3}].name`}
          control={control}
          render={({ field }) => (
            <InputBase onClick={onInputClick} fullWidth {...field} />
          )}
        />

        <StepActions
          {...{
            handleOpenMenu,
            anchorEl,
            open,
            handleCloseMenu,
            handleDeleteStep,
            handleAddNestedStep,
            index: index3,
          }}
        />
      </AccordionSummary>
      <AccordionDetails>
        {fields4?.map((item4, index4) => (
          <Step4
            key={item4.id}
            {...{ item4, index1, index2, index3, index4, control, remove4 }}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

// MATERIAL
import { Button, buttonClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ComboBoxButton = styled(Button)(({ theme }) => ({
  justifyContent: "flex-start",
  alignItems: "center",
  borderColor: theme.palette.lightSky[200],
  backgroundColor: "white",
  color: "inherit",
  borderRadius: 16,
  textTransform: "none",
  [`& .${buttonClasses.endIcon}`]: {
    marginLeft: "auto",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      marginLeft: 10,
    },
  },
}));

export const Ul = styled("ul")({
  listStyleType: "none",
});

// MATERIAL
import {
  TableCell as MuiTableCell,
  tableCellClasses,
  ButtonBase,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const TableCell = styled(MuiTableCell)({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 12,
    fontWeight: 900,
    fontFamily: "Mont",
    color: "hsla(0, 0%, 40%, 1)",
    borderBottom: "none",
    backgroundColor: "hsla(0, 0%, 96%, 1)",
    boxShadow:
      "inset 0px -1px 0px rgba(0, 0, 0, 0.3), inset 0px 1px 0px rgba(0, 0, 0, 0.3)",
    paddingTop: 12,
    paddingBottom: 12,
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
    boxShadow: "inset 0px -1px 0px 0px hsla(0, 0%, 0%, 0.08)",
    fontSize: 12,
    paddingTop: 12,
    paddingBottom: 12,
  },
  [`&.${tableCellClasses.footer}`]: {
    borderBottom: "none",
  },
});

export const ActionButton = styled(ButtonBase)(({ theme }) => ({
  height: 26,
  width: 26,
  borderRadius: 5,
  boxShadow:
    "0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08)",

  transition: theme.transitions.create("box-shadow", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.short,
  }),

  "&:hover:not(:disabled)": {
    boxShadow:
      "0px 2px 4px 2px rgb(24 39 75 / 12%), 0px 4px 4px -2px rgb(24 39 75 / 8%)",
  },

  "&:disabled": {
    backgroundColor: "hsla(0, 0%, 96%, 1)",
    "& > svg": {
      opacity: 0.5,
    },
  },
}));

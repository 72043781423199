export default class TimedAlertActions {
  static SHOW_ALERT = "SHOW_ALERT";

  static showSuccessAlert = ({ message, title }) => {
    return {
      type: TimedAlertActions.SHOW_ALERT,
      payload: { message, title, type: "SUCCESS" },
    };
  };

  static showErrorAlert = (message) => {
    return {
      type: TimedAlertActions.SHOW_ALERT,
      payload: { message, type: "ERROR" },
    };
  };
}

// MATERIAL
import { SvgIcon } from "@mui/material";

export const AddIcon = ({ sx, ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 14 14" sx={{ height: 14, ...sx }} {...rest}>
      <path
        d="M13.1948 6.19482H7.81244V0.805181C7.81244 0.362694 7.44974 0 7 0C6.55026 0 6.19482 0.362694 6.19482 0.805181V6.18756H0.805181C0.362694 6.19482 0 6.55751 0 7C0 7.44974 0.362694 7.80518 0.805181 7.80518H6.18756V13.1876C6.18756 13.6373 6.55026 13.9927 6.99275 13.9927C7.43523 13.9927 7.79793 13.6301 7.79793 13.1876V7.80518H13.1803C13.6301 7.80518 13.9855 7.44249 13.9855 7C14 6.55751 13.6373 6.19482 13.1948 6.19482Z"
        fill="#212121"
      />
    </SvgIcon>
  );
};

// REACT
import { useMutation, useQueryClient } from "react-query";

// SERVICES
import { getAxiosInstance } from "../../services/axiosConfig";

const saveEvent = async ({ id, ...form }) => {
  const { axiosInstance } = getAxiosInstance();

  if (id) {
    const { data } = await axiosInstance.put(`/events/${id}`, { ...form });

    return data;
  } else {
    const {
      data: { data },
    } = await axiosInstance.post("/events", {
      ...form,
    });

    return data;
  }
};

export const useSaveEvent = ({ onSuccess }) => {
  const queryClient = useQueryClient();

  const saveEventMutation = useMutation(saveEvent, {
    // When mutate is called:
    onMutate: async (event) => {
      // Snapshot previous query data
      const previousQueryData = queryClient.getQueryData(["events"]);
      const action = event?.id ? "update" : "insert";

      return { previousQueryData, action };
    },

    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (error, variables, context) => {},

    // Always refetch after error or success:
    onSettled: (newEvent, error, variables, context) => {
      queryClient.invalidateQueries(["events"]);
    },

    // Do something if query succeed
    onSuccess: (newEvent, variables, context) => {
      if (onSuccess) onSuccess();
    },
  });

  return saveEventMutation;
};

// REACT
import { useParams } from "react-router-dom";

// COMPONENTS
import { TrackingForm } from "../../../components/pages/TrackingForm";
import { Loading } from "../../../components/common/Loading";

// HOOKS
import { useGetWorkflow } from "../../../hooks/Workflows/useGetWorkflow";

export const EditTracking = () => {
  const { id } = useParams();

  const { data, isLoading } = useGetWorkflow(id);

  if (isLoading) return <Loading />;

  return <TrackingForm {...{ id, defaultValues: data }} />;
};

// MATERIAL
import { SvgIcon } from "@mui/material";

export const ExpandLess = ({ sx, ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 12 6" sx={{ height: 6, ...sx }} {...rest}>
      <path
        d="M0.664787 5.82993C0.890443 6.05669 1.24674 6.05669 1.47239 5.82993L5.90829 1.37245L10.5402 5.8359C10.7658 6.05669 11.128 6.05072 11.3418 5.818C11.5556 5.58528 11.5556 5.22725 11.324 5.01243L6.29428 0.161114C6.18146 0.0537048 6.03894 -4.77421e-07 5.89642 -4.89881e-07C5.74796 -5.02859e-07 5.60544 0.0537042 5.49261 0.16708L0.664787 5.0184C0.445071 5.24515 0.445071 5.60318 0.664787 5.82993Z"
        fill="#FF7E44"
      />
    </SvgIcon>
  );
};

// MATERIAL
import { createTheme } from "@mui/material/styles";

// FONTS
import LatoRegular from "../assets/fonts/Lato/Lato-Regular.ttf";
import LatoBold from "../assets/fonts/Lato/Lato-Bold.ttf";
import LatoBlack from "../assets/fonts/Lato/Lato-Black.ttf";
import MontRegular from "../assets/fonts/Mont/Mont-Regular.otf";
import MontSemibold from "../assets/fonts/Mont/Mont-SemiBold.otf";
import MontBold from "../assets/fonts/Mont/Mont-Bold.otf";
import MontBlack from "../assets/fonts/Mont/Mont-Black.otf";

let theme = createTheme({
  palette: {
    primary: {
      main: "hsla(226, 100%, 60%, 1)",
      light: "hsla(200, 100%, 58%, 1)",
      contrastText: "hsla(0, 0%, 100%, 1)",
    },
    secondary: {
      main: "hsla(19, 100%, 63%, 1)",
      contrastText: "hsla(0, 0%, 100%, 1)",
    },
    lightSky: {
      main: "hsla(200, 100%, 96%, 1)",
      light: "hsla(200, 100%, 99%, 1)",
      200: "hsla(200, 72%, 29%, 1)",
    },
    active: {
      main: "hsla(142, 38%, 40%, 1)",
      contrastText: "hsla(0, 0%, 100%, 1)",
    },
  },
  typography: {
    fontFamily: "Lato",
    h1: {
      fontFamily: "Mont",
    },
    h2: {
      fontFamily: "Mont",
    },
    h3: {
      fontFamily: "Mont",
    },
    h4: {
      fontFamily: "Mont",
    },
    h5: {
      fontFamily: "Mont",
    },
    h6: {
      fontFamily: "Mont",
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: `
          @font-face {
            font-family: "Lato";
            src: local("Lato"), url(${LatoRegular}) format("truetype");
          }
          @font-face {
            font-family: "Lato";
            font-weight: 700;
            src: local("Lato"), url(${LatoBold}) format("truetype");
          }
          @font-face {
            font-family: "Lato";
            font-weight: 900;
            src: local("Lato"), url(${LatoBlack}) format("truetype");
          }
          @font-face {
            font-family: "Mont";
            font-weight: 400;
            src: local("Mont"), url(${MontRegular}) format("opentype");
          }
          @font-face {
            font-family: "Mont";
            font-weight: 600;
            src: local("Mont"), url(${MontSemibold}) format("opentype");
          }
          @font-face {
            font-family: "Mont";
            font-weight: 700;
            src: local("Mont"), url(${MontBold}) format("opentype");
          }
          @font-face {
            font-family: "Mont";
            font-weight: 900;
            src: local("Mont"), url(${MontBlack}) format("opentype");
          }

          #root {
            display: flex;
          }
          `,
    },
  },
});

theme = createTheme(theme, {
  palette: {
    background: {
      default: theme.palette.lightSky.light,
    },
  },
});

export default theme;

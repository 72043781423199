// MATERIAL
import { SvgIcon } from "@mui/material";

export const LeftChevronIcon = ({ sx, ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 4 8" sx={{ height: 8, width: 4, ...sx }} {...rest}>
      <path
        d="M3.88662 7.88015C4.03779 7.71604 4.03779 7.45692 3.88662 7.2928L0.914968 4.0667L3.8906 0.698067C4.03779 0.533955 4.03381 0.270511 3.87867 0.115035C3.72352 -0.0404397 3.48483 -0.0404397 3.34162 0.127992L0.10741 3.78598C0.0358034 3.86803 2.8578e-07 3.97168 2.87016e-07 4.07533C2.88304e-07 4.1833 0.0358031 4.28695 0.111388 4.36901L3.3456 7.88015C3.49677 8.03995 3.73546 8.03995 3.88662 7.88015Z"
        fill="white"
      />
    </SvgIcon>
  );
};

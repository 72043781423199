// MATERIAL
import { SvgIcon } from "@mui/material";

export const ExpandIcon = ({ sx, ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 11 6" sx={{ height: 6, ...sx }} {...rest}>
      <path
        d="M10.8352 0.170065C10.6096 -0.0566882 10.2533 -0.0566882 10.0276 0.170065L5.59171 4.62755L0.959842 0.164097C0.734187 -0.0566882 0.371952 -0.050721 0.158174 0.181999C-0.0556046 0.414719 -0.0556046 0.77275 0.175989 0.987568L5.20572 5.83889C5.31854 5.9463 5.46106 6 5.60358 6C5.75204 6 5.89456 5.9463 6.00739 5.83292L10.8352 0.981601C11.0549 0.754848 11.0549 0.396817 10.8352 0.170065Z"
        fill="#FF7E44"
      />
    </SvgIcon>
  );
};

import { SvgIcon } from "@mui/material";

export const CloseIcon = ({ sx, ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 17 17" sx={{ height: 17, ...sx }} {...rest}>
      <path
        d="M9.97988 8.18L15.9899 2.17C16.4899 1.67 16.4899 0.869995 15.9899 0.369995C15.4899 -0.130005 14.6899 -0.130005 14.1899 0.369995L8.17988 6.38L2.17988 0.369995C1.67988 -0.130005 0.879883 -0.130005 0.379883 0.369995C-0.120117 0.869995 -0.120117 1.67 0.379883 2.17L6.38988 8.18L0.379883 14.19C-0.120117 14.69 -0.120117 15.49 0.379883 15.99C0.629883 16.24 0.949883 16.36 1.27988 16.36C1.60988 16.36 1.92988 16.24 2.17988 15.99L8.18988 9.97999L14.1999 15.99C14.4499 16.24 14.7699 16.36 15.0999 16.36C15.4299 16.36 15.7499 16.24 15.9999 15.99C16.4999 15.49 16.4999 14.69 15.9999 14.19L9.97988 8.18Z"
        fill="#212121"
      />
    </SvgIcon>
  );
};

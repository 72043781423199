// REACT
import { useQuery } from "react-query";

// SERVICES
import { getAxiosInstance } from "../../services/axiosConfig";

const getAllEvents = async () => {
  const { axiosInstance } = getAxiosInstance();

  const {
    data: { data },
  } = await axiosInstance.get("/events");

  return data;
};

export const useGetEvents = () =>
  useQuery(["events"], getAllEvents, {
    staleTime: 1000 * 60 * 5, // 5 minutes after data is considered stale. If the data is fresh it will be returned from the cache
  });

export const useDisabledGetEvents = () =>
  useQuery(["events"], getAllEvents, {
    staleTime: 1000 * 60 * 5, // 5 minutes after data is considered stale. If the data is fresh it will be returned from the cache
    enabled: false,
  });

// REACT
import { Controller } from "react-hook-form";

// MATERIAL
import {
  Autocomplete,
  Box,
  Checkbox,
  InputBase,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { ClickAwayListener } from "@mui/material";

// COMPONENTS
import { ComboBoxButton, Ul } from "./styles";
import { StoreIcon } from "../../../../icons/StoreIcon";
import { SelectIcon } from "../../../../icons/SelectIcon";
import { Popper } from "../../../../common/Popper/styles";
import { PopperComponent } from "../../../../common/Popper";
import { SearchIcon } from "../../../../icons/SearchIcon";

// HOOKS
import { useGetClients } from "../../../../../hooks/Clients/useGetClients";

export const ComboBox = ({
  control,
  id,
  handleClick,
  open,
  anchorEl,
  handleClose,
  selectedClients,
  setPendingClients,
}) => {
  const theme = useTheme();

  const { isLoading } = useGetClients();
  const clients = [
    {
      name: "Rodriguez, Buckridge and Morissette",
      rut: "41 568 775-9",
      account: "96801150-108-1",
    },
    {
      name: "Johns and Sons",
      rut: "10 291 390-6",
      account: "96801150-108-2",
    },
    {
      name: "King Inc",
      rut: "15 325 827-9",
      account: "96801150-108-3",
    },
    {
      name: "McCullough, Streich and Towne",
      rut: "21 853 774-K",
      account: "96801150-108-4",
    },
    {
      name: "Paucek Group",
      rut: "23 313 713-8",
      account: "96801150-108-5",
    },
    {
      name: "Gerlach-Bergnaum",
      rut: "24 399 986-3",
      account: "96801150-108-6",
    },
    {
      name: "Bergstrom LLC",
      rut: "25 093 627-3",
      account: "96801150-108-7",
    },
    {
      name: "Thiel Inc",
      rut: "29 581 773-9",
      account: "96801150-108-8",
    },
  ];

  return (
    <>
      <ComboBoxButton
        variant="outlined"
        aria-describedby={id}
        onClick={handleClick}
        fullWidth
        disableRipple
        startIcon={<StoreIcon />}
        endIcon={
          <>
            {isLoading ? <CircularProgress color="inherit" size={15} /> : null}
            <SelectIcon {...{ open }} />
          </>
        }
      >
        {selectedClients?.length === 0
          ? "Elige un Cliente"
          : `${selectedClients.length} clientes seleccionados`}
      </ComboBoxButton>

      <Popper {...{ id, open, anchorEl, placement: "bottom" }}>
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Box sx={{ padding: "10px 16px 0" }}>Buscar</Box>
            <Controller
              name="clients"
              control={control}
              render={({ field: { onChange, value: autoCompleteValue } }) => (
                <Autocomplete
                  open
                  multiple
                  disableCloseOnSelect
                  PopperComponent={PopperComponent}
                  value={autoCompleteValue}
                  renderTags={() => null}
                  loading={isLoading}
                  loadingText="Cargando..."
                  noOptionsText="No hay clientes"
                  onClose={(event, reason) => {
                    if (reason === "escape") handleClose();
                  }}
                  onChange={(event, newValue, reason) => {
                    if (
                      event.type === "keydown" &&
                      event.key === "Backspace" &&
                      reason === "removeOption"
                    )
                      return;

                    onChange(newValue);
                    setPendingClients(newValue);
                  }}
                  ListboxComponent={Ul}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        checked={selected}
                        color="secondary"
                        sx={{ mr: 1 }}
                      />
                      <Box
                        sx={{
                          flexGrow: 1,
                          "& span": {
                            color: "#586069",
                          },
                        }}
                      >
                        {option.name}
                        <br />
                        <div>
                          <span>
                            RUT: <strong>{option.rut}</strong>
                          </span>
                          {" | "}
                          <span>
                            Cuenta: <strong>{option.account}</strong>
                          </span>
                        </div>
                      </Box>
                    </li>
                  )}
                  options={[...(clients || [])].sort((a, b) => {
                    // Display the selected labels first.
                    let ai = selectedClients.indexOf(a);
                    ai =
                      ai === -1
                        ? selectedClients.length + clients?.indexOf(a)
                        : ai;
                    let bi = selectedClients.indexOf(b);
                    bi =
                      bi === -1
                        ? selectedClients.length + clients?.indexOf(b)
                        : bi;
                    return ai - bi;
                  })}
                  getOptionLabel={(option) => `${option?.rut} ${option?.name}`}
                  isOptionEqualToValue={(option, value) =>
                    option.rut === value.rut
                  }
                  renderInput={(params) => (
                    <Box
                      sx={{
                        border: `1px solid ${theme.palette.lightSky[200]}`,
                        borderRadius: 4,
                        my: 1,
                        mx: 2,
                      }}
                    >
                      <InputBase
                        ref={params.InputProps.ref}
                        inputProps={params.inputProps}
                        autoFocus
                        placeholder="Filtrar clientes"
                        startAdornment={<SearchIcon />}
                        fullWidth
                        sx={{ paddingInlineStart: 1 }}
                      />
                    </Box>
                  )}
                />
              )}
            />
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

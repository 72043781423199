// REACT
import { useQuery } from "react-query";

// SERVICES
import { getAxiosInstance } from "../../services/axiosConfig";

const getAllClients = async () => {
  const { axiosInstance } = getAxiosInstance();

  const {
    data: { clients },
  } = await axiosInstance.get("clients");

  return clients;
};

export const useGetClients = () =>
  useQuery(["clients"], getAllClients, {
    staleTime: 1000 * 60 * 10, // 10 minutes after data is considered stale. If the data is fresh it will be returned from the cache
  });

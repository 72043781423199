// MATERIAL
import { CircularProgress } from "@mui/material";

// COMPONENTS
import { Centered } from "./styles";

export const Loading = () => {
  return (
    <Centered>
      <CircularProgress />
    </Centered>
  );
};

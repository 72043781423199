import { SvgIcon } from "@mui/material";

export const AddCircleIcon = ({ sx, ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 13 12" sx={{ height: 12, ...sx }} {...rest}>
      <path
        d="M6.50249 0C3.19266 0 0.5 2.69266 0.5 6.00249C0.5 9.31232 3.19266 12 6.50249 12C9.81232 12 12.5 9.30734 12.5 6.00249C12.5 2.69764 9.80734 0 6.50249 0ZM6.50249 11.2435C3.61074 11.2435 1.26151 8.89423 1.26151 6.00249C1.26151 3.11074 3.61074 0.76151 6.50249 0.76151C9.39423 0.76151 11.7435 3.11074 11.7435 6.00249C11.7435 8.88926 9.38926 11.2435 6.50249 11.2435Z"
        fill="#FF7E44"
      />
      <path
        d="M9.40917 5.61925H6.88075V3.09083C6.88075 2.88179 6.71153 2.71257 6.50249 2.71257C6.29345 2.71257 6.12422 2.88179 6.12422 3.09083V5.61925H3.59083C3.38179 5.61925 3.21257 5.78847 3.21257 5.99751C3.21257 6.20655 3.38179 6.37578 3.59083 6.37578H6.11925V8.90419C6.11925 9.11323 6.28847 9.28246 6.49751 9.28246C6.70655 9.28246 6.87578 9.11323 6.87578 8.90419V6.37578H9.40419C9.61323 6.37578 9.78246 6.20655 9.78246 5.99751C9.78743 5.78847 9.61821 5.61925 9.40917 5.61925Z"
        fill="#FF7E44"
      />
    </SvgIcon>
  );
};

// REACT
import { useState } from "react";
import { Controller } from "react-hook-form";

// MATERIAL
import { InputBase, AccordionSummary } from "@mui/material";

// COMPONENTS
import { Accordion } from "./styles";
import { StepActions } from "../StepActions";
import { ExpandIcon } from "../../../../icons/ExpandIcon";

export const Step4 = ({
  item4,
  index1,
  index2,
  index3,
  index4,
  control,
  remove4,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (event) => {
    if (expanded) event?.stopPropagation();
    setAnchorEl(null);
  };

  const handleDeleteStep = (event) => {
    handleCloseMenu(event);
    remove4(index4);
  };

  const onInputClick = (event) => {
    event.stopPropagation();
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion
      expanded={expanded === "panel4"}
      onChange={handleChange("panel4")}
      elevation={0}
    >
      <AccordionSummary
        expandIcon={<ExpandIcon />}
        aria-controls="panel4a-content"
        id="panel4a-header"
      >
        <Controller
          name={`steps[${index1}].steps[${index2}].steps[${index3}].steps[${index4}].name`}
          control={control}
          render={({ field }) => (
            <InputBase onClick={onInputClick} fullWidth {...field} />
          )}
        />

        <StepActions
          {...{
            handleOpenMenu,
            anchorEl,
            open,
            handleCloseMenu,
            handleDeleteStep,
            index: index4,
          }}
        />
      </AccordionSummary>
    </Accordion>
  );
};

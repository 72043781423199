// MATERIAL
import { Typography } from "./styles";

export const PageTitle = ({ children, ...rest }) => {
  return (
    <Typography variant="h3" component="h1" align="center" {...rest}>
      {children}
    </Typography>
  );
};

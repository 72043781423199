// MATERIAL
import { SvgIcon } from "@mui/material";

export const LeftArrowIcon = ({ sx, ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 18 13" sx={{ height: 13, ...sx }} {...rest}>
      <path
        d="M16.5039 5.02267H3.99384L6.92384 1.96267C7.36384 1.50267 7.35384 0.762667 6.89384 0.322667C6.43384 -0.117333 5.69384 -0.107333 5.25384 0.352667L0.323845 5.48267C-0.116155 5.94267 -0.106155 6.66267 0.343845 7.11267L5.26384 12.0327C5.49384 12.2627 5.78384 12.3727 6.08384 12.3727C6.38384 12.3727 6.67384 12.2627 6.90384 12.0327C7.35384 11.5827 7.35384 10.8427 6.90384 10.3927L3.87384 7.35267H16.5039C17.1439 7.35267 17.6639 6.83267 17.6639 6.19267C17.6639 5.55267 17.1439 5.02267 16.5039 5.02267Z"
        fill="#2BB9FF"
      />
    </SvgIcon>
  );
};

// REACT
import { Controller } from "react-hook-form";

// MATERIAL
import {
  FormControl,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

export const RadioGroup = ({ control }) => {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">¿Qué tipo de flujo le gustaría?</FormLabel>
      <Controller
        control={control}
        name="typeFlow"
        defaultValue=""
        render={({ field }) => (
          <MuiRadioGroup row {...field}>
            <FormControlLabel
              value="Estandar"
              control={<Radio color="secondary" />}
              label="Estandar"
            />
            <FormControlLabel
              value="Personalizado"
              control={<Radio color="secondary" />}
              label="Personalizado"
            />
          </MuiRadioGroup>
        )}
      />
    </FormControl>
  );
};

// REACT
import { useState } from "react";
import { useFieldArray } from "react-hook-form";

// MATERIAL
import {
  Grid,
  Stack,
  InputLabel,
  Typography,
  Box,
  IconButton,
  MenuItem,
  FormControlLabel,
} from "@mui/material";

// COMPONENTS
import { PaperTitle } from "../../../common/PaperTitle";
import { HighlightBox } from "../../../common/HighlightBox";
import { Pre, Button, Ul, Li } from "./styles";
import { TextField } from "../../../common/TextField";
import { Paper } from "../../../common/Paper";
import { SearchIcon } from "../../../icons/SearchIcon";
import { DeleteIcon } from "../../../icons/DeleteIcon";
import { StyledSwitch } from "../../../common/SwitchButton/styles";

export const Params = ({ control, watch }) => {
  const [name, setName] = useState("");
  const [typeParam, setTypeParam] = useState("String");
  const [filter, setFilter] = useState("");
  const [isRequired, setIsRequired] = useState(false);

  const { fields, append, remove } = useFieldArray({ control, name: "params" });

  const restExample = () => {
    const { code, params } = watch();

    return {
      tracking: "OS123456",
      code,
      ...(params?.length > 0 && {
        params: params.map(({ name, typeParam }) => ({ [name]: typeParam })),
      }),
    };
  };

  const handleAppend = () => {
    if (name === "") return;
    append({ name, typeParam, required: isRequired });
    setName("");
    setTypeParam("String");
    setIsRequired(false);
  };

  const handleRemove = (id) => {
    const index = fields.findIndex((param) => param.id === id);
    remove(index);
    setFilter("");
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={9}>
        <PaperTitle>
          Elija qué parámetros le gustaría agregar al evento.
        </PaperTitle>
        <Typography variant="subtitle1">
          Ingresse el nombre de lo parametro:
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <HighlightBox padding={20}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center  "
              >
                <FormControlLabel
                  value="end"
                  control={
                    <StyledSwitch
                      color="secondary"
                      checked={isRequired}
                      onChange={() => setIsRequired((r) => !r)}
                    />
                  }
                  label="Obligatorio?"
                  labelPlacement="end"
                />

                <Button
                  type="button"
                  variant="contained"
                  color="secondary"
                  disabled={name === ""}
                  onClick={handleAppend}
                >
                  Guardar
                </Button>
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" alignItems="flex-end" spacing={1}>
                <div style={{ flexGrow: 1 }}>
                  <InputLabel htmlFor="code">Nombre parametro</InputLabel>
                  <TextField
                    type="text"
                    id="code"
                    fullWidth
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    placeholder="Ingrese un código"
                    sx={{
                      ".MuiInputBase-root": { backgroundColor: "#fff" },
                    }}
                  />
                </div>

                <div style={{ flexBasis: 150 }}>
                  <InputLabel htmlFor="code">Tipo del parametro</InputLabel>

                  <TextField
                    select
                    value={typeParam}
                    fullWidth
                    onChange={(event) => setTypeParam(event.target.value)}
                    sx={{
                      ".MuiInputBase-root": { backgroundColor: "#fff" },
                    }}
                  >
                    <MenuItem value="String">String</MenuItem>
                    <MenuItem value="Number">Number</MenuItem>
                  </TextField>
                </div>
              </Stack>
            </Grid>
          </Grid>
        </HighlightBox>

        <Pre>
          <h6>Ejemplo rest:</h6>
          {JSON.stringify(restExample(), null, 2)}
        </Pre>
      </Grid>
      <Grid item xs={6}>
        {fields.length > 0 && (
          <Paper noPadding>
            <Box padding={2}>
              <InputLabel>Búsqueda un parámetro</InputLabel>
              <TextField
                type="text"
                value={filter}
                onChange={(event) => setFilter(event.target.value)}
                placeholder="Escriba un parámetro"
                fullWidth
                InputProps={{
                  endAdornment: <SearchIcon />,
                }}
              />
            </Box>
            <Ul>
              {fields
                .filter((param) => param?.name?.includes(filter))
                .map((filteredParams) => (
                  <Li key={`${filteredParams.id}`}>
                    {filteredParams.name}
                    <IconButton onClick={() => handleRemove(filteredParams.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Li>
                ))}
            </Ul>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
};

// MATERIAL
import { ButtonBase } from "@mui/material";
import { styled } from "@mui/material/styles";

// COMPONENTS
import { LeftArrowIcon } from "../../icons/LeftArrowIcon";

export const BackButton = styled(({ label, ...rest }) => (
  <ButtonBase {...rest}>
    <LeftArrowIcon />
    {label}
  </ButtonBase>
))(({ theme }) => ({
  color: theme.palette.primary.light,
  height: 28,
  width: 110,
  marginRight: "auto",
  marginBottom: 7,
  borderRadius: 3,
}));

// REACT
import { useState } from "react";

// COMPONENTS
import { PageContainer } from "../../components/common/PageContainer";
import { Paper } from "../../components/common/Paper";
import { PageTitle } from "../../components/common/PageTitle";
import { TableHeader } from "../../components/pages/TrackingList/Table/TableHeader";
import { TrackingListTable } from "../../components/pages/TrackingList/Table";
import { Loading } from "../../components/common/Loading";

// HOOKS
import { useGetWorkflows } from "../../hooks/Workflows/useGetWorkflows";

const headCells = [
  { id: "code", label: "Cód. API" },
  { id: "status", label: "Status" },
  { id: "description", label: "Descripción" },
  { id: "actions", label: "Acciones", disableSorting: true },
];

export const TrackingList = () => {
  const { data, isLoading } = useGetWorkflows();

  const [filterFn, setFilterFn] = useState({
    fn: (trackings) => {
      return trackings;
    },
  });

  const handleSearch = (event) => {
    const { value } = event.target;

    setFilterFn({
      fn: (trackings) => {
        if (value === "") return trackings;
        else
          return trackings.filter(
            (tracking) =>
              tracking.code.toLowerCase().includes(value) ||
              tracking.description.toLowerCase().includes(value)
          );
      },
    });
  };

  if (isLoading) return <Loading />;

  return (
    <PageContainer spacing={2}>
      <PageTitle>Listado de Flujo Tracking</PageTitle>

      <Paper elevation={3} noPadding>
        <TableHeader {...{ handleSearch }} />
        <TrackingListTable {...{ data, headCells, filterFn }} />
      </Paper>
    </PageContainer>
  );
};

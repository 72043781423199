// MATERIAL
import { SvgIcon } from "@mui/material";

export const PencilIcon = ({ sx, ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 10 14" sx={{ height: 14, ...sx }} {...rest}>
      <path
        d="M9.63983 1.79307L7.1838 0.329974C6.88071 0.149422 6.48046 0.250829 6.2968 0.55914L1.18843 9.13437C0.920711 9.58377 0.754396 10.1007 0.710158 10.6265L0.524316 12.8877C0.498196 13.2049 0.653524 13.5027 0.925255 13.6646L0.930481 13.6677C1.20221 13.8296 1.54121 13.8191 1.8077 13.6451L3.68976 12.3874C4.11446 12.1023 4.48358 11.7204 4.74507 11.2814L9.86589 2.68529C10.0496 2.37698 9.94815 1.97673 9.63983 1.79307ZM8.91483 2.4515L8.07122 3.86764L6.11162 2.70028L6.95523 1.28414L8.91483 2.4515ZM3.17508 11.5993L1.48559 12.724L1.65131 10.6987C1.68243 10.3137 1.80539 9.94086 2.00151 9.61165L5.63745 3.50814L7.59183 4.67239L3.94655 10.7916C3.75354 11.1156 3.4905 11.3907 3.17508 11.5993Z"
        fill="#212121"
      />
    </SvgIcon>
  );
};

// MATERIAL
import { Menu as MuiMenu } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Menu = styled(MuiMenu)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 15,
    minWidth: 35,
    boxShadow: theme.shadows[3],
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      padding: "12px 0 !important",
      flexDirection: "column",
      justifyContent: "center",
      "& .MuiListItemIcon-root": {
        minWidth: 0,
      },
    },
  },
}));

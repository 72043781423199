// REACT
import { useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";

// MATERIAL
import { InputBase, AccordionSummary, AccordionDetails } from "@mui/material";

// COMPONENTS
import { Accordion } from "./styles";
import { Step2 } from "../Step2";
import { StepActions } from "../StepActions";
import { ExpandIcon } from "../../../../icons/ExpandIcon";

export const Step1 = ({ item1, index1, control, remove1 }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const open = Boolean(anchorEl);

  const {
    fields: fields2,
    append: append2,
    remove: remove2,
  } = useFieldArray({
    control,
    name: `steps[${index1}].steps`,
  });

  const handleOpenMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (event) => {
    if (expanded) event?.stopPropagation();
    setAnchorEl(null);
  };

  const handleDeleteStep = (event) => {
    handleCloseMenu(event);
    remove1(index1);
  };

  //   const handleFocusStep = () => {
  //     handleCloseMenu();
  //     console.log("🚀 ~ input", `steps[${index1}].name`);
  //     handleSetFocus(`steps[${index1}].name`);
  //   };

  const handleAddNestedStep = (event) => {
    handleCloseMenu(event);
    append2({ name: `Paso ${index1 + 1}.${fields2?.length + 1}`, steps: [] });
  };

  const onInputClick = (event) => {
    event.stopPropagation();
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
      elevation={4}
    >
      <AccordionSummary
        expandIcon={<ExpandIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Controller
          name={`steps[${index1}].name`}
          control={control}
          render={({ field }) => (
            <InputBase onClick={onInputClick} fullWidth {...field} />
          )}
        />

        <StepActions
          {...{
            handleOpenMenu,
            anchorEl,
            open,
            handleCloseMenu,
            handleDeleteStep,
            handleAddNestedStep,
            index: index1,
          }}
        />
      </AccordionSummary>
      <AccordionDetails>
        {fields2?.map((item2, index2) => (
          <Step2
            key={item2.id}
            {...{ item2, index1, index2, control, remove2 }}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

// MATERIAL
import { SvgIcon } from "@mui/material";

export const TruckIcon = ({ sx, ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 25 18" sx={{ height: 18 }} {...rest}>
      <path
        d="M22.9422 7.55717H22.701L20.6307 3.75336C20.299 3.13789 19.6558 2.76457 18.9623 2.76457H15.9271V1.5639C15.9271 0.696188 15.2236 0 14.3693 0H2.05779C1.19347 0 0.5 0.696188 0.5 1.5639V14.3778C0.5 15.2354 1.19347 15.9417 2.05779 15.9417H3.44472C3.67588 17.1121 4.71106 18 5.94724 18C7.18342 18 8.21859 17.1121 8.44975 15.9417H14.3693C14.3894 15.9417 14.3995 15.9417 14.4196 15.9417H16.6106C16.8417 17.1121 17.8769 18 19.1131 18C20.3492 18 21.3844 17.1121 21.6156 15.9417H22.9724C23.8166 15.9417 24.5 15.2556 24.5 14.4081V9.13117C24.5 8.26345 23.7965 7.55717 22.9422 7.55717ZM14.3693 14.3778H8.25879C7.85678 13.4899 6.97236 12.8744 5.93719 12.8744C4.90201 12.8744 4.01759 13.4899 3.61558 14.3778H2.04774V1.5639H14.3593V14.3778H14.3693ZM18.9523 4.31839C19.0829 4.31839 19.2035 4.38901 19.2638 4.5L20.9322 7.55717H15.9271V4.31839H18.9523ZM22.9422 14.3778H21.4347C21.0327 13.4899 20.1482 12.8744 19.1131 12.8744C18.0779 12.8744 17.1935 13.4899 16.7915 14.3778H15.9673V9.12108L22.9422 9.13117V14.3778Z"
        fill="#212121"
      />
    </SvgIcon>
  );
};

// MATERIAL
import { Avatar as MuiAvatar } from "@mui/material";

// COMPONENTS
import { Button } from "./styles";

export const AvatarButton = ({ src, ...rest }) => {
  return (
    <Button {...rest}>
      <MuiAvatar src={src} sx={{ height: 32, width: 32 }} />
    </Button>
  );
};

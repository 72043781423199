// REACT
import { useQueryClient, useMutation } from "react-query";

// SERVICES
import { getAxiosInstance } from "../../services/axiosConfig";

const deleteWorkflowById = async (oldWorkflow) => {
  const { axiosInstance } = getAxiosInstance();

  const { data } = await axiosInstance.delete(`/workflows/${oldWorkflow._id}`);

  return data;
};

export const useDeleteWorkflow = () => {
  const queryClient = useQueryClient();

  const deleteUserMutation = useMutation(deleteWorkflowById, {
    // When mutate is called:
    onMutate: async (oldWorkflow) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(["workflows"]);

      // Snapshot the previous value
      const previousQueryData = queryClient.getQueryData(["workflows"]);

      const newQueryData = previousQueryData.filter(
        (workflow) => workflow._id !== oldWorkflow._id
      );

      // Optimistically update to the new value
      queryClient.setQueryData(["workflows"], newQueryData);

      // Return a context object with the snapshotted values
      return { oldWorkflow, previousQueryData };
    },

    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (error, variables, { previousQueryData }) => {
      queryClient.setQueryData(["workflows"], previousQueryData);
    },

    // Always refetch after error or success:
    onSettled: (data, error, variables, { oldWorkflow }) => {
      queryClient.invalidateQueries(["workflows"]);
    },

    // Do something if query succeed
    onSuccess: (data, variables, context) => {},
  });

  return deleteUserMutation;
};

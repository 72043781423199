// REACT
import { Controller } from "react-hook-form";

// MATERIAL
import { Grid, Typography, InputLabel } from "@mui/material";

// COMPONENTS
import { PaperTitle } from "../../../common/PaperTitle";
import { HighlightBox } from "../../../common/HighlightBox";
import { SwitchButton } from "../../../common/SwitchButton";
import { TextField } from "../../../common/TextField";

export const Form = ({ control, watch }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={9}>
        <PaperTitle>Crea un nuevo evento</PaperTitle>
        <Typography variant="subtitle1">
          Ingresse los datos de lo evento
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <HighlightBox centered padding={4}>
          <SwitchButton
            {...{ control, name: "enabled", label: "Activar evento" }}
          />
        </HighlightBox>
      </Grid>

      <Grid item xs={3}>
        <InputLabel>Código del evento</InputLabel>
        <Controller
          control={control}
          name="code"
          rules={{
            required: "Campo obligatorio",
            pattern: {
              value: /^(\S+$)/,
              message: "El código no puede tener espacios",
            },
          }}
          render={({ field: { value, ...rest }, fieldState: { error } }) => (
            <TextField
              fullWidth
              required
              value={value?.toUpperCase()}
              error={Boolean(error)}
              helperText={error?.message}
              // TODO: NÃO PODE TER ESPAÇOS, E SÓ PODE LETRA MAIUSCULA
              placeholder="Escriba el código del evento"
              {...rest}
            />
          )}
        />
      </Grid>

      <Grid item xs={6}>
        <InputLabel>Descripción</InputLabel>
        <Controller
          control={control}
          name="description"
          rules={{ required: "Campo obligatorio" }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              required
              error={Boolean(error)}
              helperText={error?.message}
              placeholder="Ingrese una descripción"
              {...field}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

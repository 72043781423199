// REACT
import { useState, cloneElement } from "react";

// UNIVERSAL
import { useSession } from "@blue-express/bx-lib-universal-frontend";

// MATERIAL
import { AppBar, Menu, MenuItem, useScrollTrigger } from "@mui/material";

// COMPONENTS
import { Toolbar } from "./styles";
import { AvatarButton } from "./AvatarButton";
import { NotificationsButton } from "./NotificationsButton";
import { SquaresButton } from "./SquaresButton";

// ASSETS
import AvatarNaranjo from "../../../assets/images/avatar-naranjo.png";

const ElevationScroll = ({ children, window }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return cloneElement(children, {
    elevation: trigger ? 3 : 0,
  });
};

export const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const { logout } = useSession();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    logout();
  };

  return (
    <ElevationScroll>
      <AppBar position="fixed">
        <Toolbar>
          <NotificationsButton />

          <SquaresButton />

          <AvatarButton
            src={AvatarNaranjo}
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
          />
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>Mi Cuenta</MenuItem>
            <MenuItem onClick={handleLogout}>Salir</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </ElevationScroll>
  );
};

// REACT
import { useQuery } from "react-query";

// SERVICES
import { getAxiosInstance } from "../../services/axiosConfig";

const getAllWorkflows = async () => {
  const { axiosInstance } = getAxiosInstance();

  const {
    data: { data },
  } = await axiosInstance.get("/workflows");

  return data;
};

export const useGetWorkflows = () =>
  useQuery(["workflows"], getAllWorkflows, {
    staleTime: 1000 * 60 * 5, // 5 minutes after data is considered stale. If the data is fresh it will be returned from the cache
  });

export const useDisabledGetWorkflows = () =>
  useQuery(["workflows"], getAllWorkflows, {
    staleTime: 1000 * 60 * 5, // 5 minutes after data is considered stale. If the data is fresh it will be returned from the cache
    enabled: false,
  });

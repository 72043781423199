import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { Buffer } from "buffer";

// MATERIAL
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./styles/theme";

// COMPONENTS
import App from "./App";
import { TimedAlert } from "./components/common/TimedAlert/TimedAlert";

// CONTEXTS
import { AlertProvider } from "./contexts/Alerts/Alerts";

// REACT QUERY
const queryClient = new QueryClient();

global.Buffer = global.Buffer || Buffer;

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <AlertProvider>
            <CssBaseline />
            <App />
            <TimedAlert />
          </AlertProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

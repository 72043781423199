// REACT
import { Controller } from "react-hook-form";

// MATERIAL
import { Autocomplete, CircularProgress } from "@mui/material";
import { TextField } from "../../../../common/TextField";

// HOOKS
import { useGetServices } from "../../../../../hooks/Services/useGetServices";

export const ServiceSelect = ({ control }) => {
  const { data: services, isLoading } = useGetServices();

  return (
    <Controller
      control={control}
      name="serviceId"
      rules={{ required: "Campo obligatorio" }}
      defaultValue={null}
      render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
        <Autocomplete
          {...rest}
          options={services || []}
          noOptionsText="No hay servico"
          onChange={(event, item) => {
            onChange(item?._id);
          }}
          getOptionLabel={(option) =>
            services?.find((service) => service?._id === option)?.name ||
            option?.name ||
            ""
          }
          isOptionEqualToValue={(option, value) => option._id === value}
          renderInput={(params) => (
            <TextField
              {...params}
              error={Boolean(error)}
              helperText={error?.message}
              placeholder="Elije el tipo del servicio"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};

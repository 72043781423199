// REACT
import { useQuery } from "react-query";

// SERVICES
import { getAxiosInstance } from "../../services/axiosConfig";

const getWorkflowById = async ({ queryKey }) => {
  const { axiosInstance } = getAxiosInstance();

  // eslint-disable-next-line no-unused-vars
  const [_key, { id }] = queryKey;

  const {
    data: { data },
  } = await axiosInstance.get(`/workflows/${id}`);

  return data[0];
};

export const useGetWorkflow = (id) =>
  useQuery(["workflows", { id }], getWorkflowById, {
    staleTime: 1000 * 60 * 10, // 5 minutes after data is considered stale. If the data is fresh it will be returned from the cache
  });

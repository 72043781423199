// MATERIAL
import { SvgIcon } from "@mui/material";

export const SquaresIcon = ({ sx, ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ height: 24, ...sx }} {...rest}>
      <path
        d="M8.6376 0H2.44472C1.10193 0 -0.000366211 1.1 -0.000366211 2.44V8.61C-0.000366211 9.96 1.10193 11.05 2.44472 11.05H8.6376C9.99042 11.05 11.0827 9.95 11.0827 8.61V2.44C11.0827 1.1 9.99042 0 8.6376 0ZM9.50942 8.62C9.50942 9.1 9.11861 9.49 8.6376 9.49H2.44472C1.96372 9.49 1.57291 9.1 1.57291 8.62V2.44C1.57291 1.96 1.96372 1.57 2.44472 1.57H8.6376C9.11861 1.57 9.50942 1.96 9.50942 2.44V8.62Z"
        fill="white"
      />
      <path
        d="M21.5549 0H15.372C14.0192 0 12.9269 1.1 12.9269 2.44V8.61C12.9269 9.96 14.0292 11.05 15.372 11.05H21.5549C22.9077 11.05 24 9.95 24 8.61V2.44C24.01 1.1 22.9077 0 21.5549 0ZM22.4367 8.62C22.4367 9.1 22.0459 9.49 21.5649 9.49H15.382C14.901 9.49 14.5102 9.1 14.5102 8.62V2.44C14.5102 1.96 14.901 1.57 15.382 1.57H21.5649C22.0459 1.57 22.4367 1.96 22.4367 2.44V8.62Z"
        fill="white"
      />
      <path
        d="M8.6376 12.63H2.44472C1.10193 12.63 -0.000366211 13.72 -0.000366211 15.07V21.25C-0.000366211 22.6 1.10193 23.69 2.44472 23.69H8.6376C9.99042 23.69 11.0827 22.59 11.0827 21.25V15.07C11.0827 13.72 9.99042 12.63 8.6376 12.63ZM9.50942 21.24C9.50942 21.72 9.11861 22.11 8.6376 22.11H2.44472C1.96372 22.11 1.57291 21.72 1.57291 21.24V15.06C1.57291 14.58 1.96372 14.19 2.44472 14.19H8.6376C9.11861 14.19 9.50942 14.58 9.50942 15.06V21.24Z"
        fill="white"
      />
      <path
        d="M21.5549 12.63H15.372C14.0192 12.63 12.9269 13.73 12.9269 15.07V21.25C12.9269 22.6 14.0292 23.69 15.372 23.69H21.5549C22.9077 23.69 24 22.59 24 21.25V15.07C24.01 13.72 22.9077 12.63 21.5549 12.63ZM22.4367 21.24C22.4367 21.72 22.0459 22.11 21.5649 22.11H15.382C14.901 22.11 14.5102 21.72 14.5102 21.24V15.06C14.5102 14.58 14.901 14.19 15.382 14.19H21.5649C22.0459 14.19 22.4367 14.58 22.4367 15.06V21.24Z"
        fill="white"
      />
    </SvgIcon>
  );
};

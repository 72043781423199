// MATERIAL
import { SvgIcon } from "@mui/material";

export const MoreVertIcon = ({ sx, ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 5 22" sx={{ height: 22, ...sx }} {...rest}>
      <path
        d="M0.255156 2.36694C0.255156 3.65961 1.31733 4.70752 2.62758 4.70752C3.93783 4.70752 5 3.65961 5 2.36694C5 1.07428 3.93783 0.0263671 2.62758 0.0263671C1.31733 0.026367 0.255156 1.07428 0.255156 2.36694Z"
        fill="#212121"
      />
      <path
        d="M5 10.8929C5 9.59727 3.94088 8.55237 2.62758 8.55237C1.31427 8.55237 0.255156 9.59727 0.255156 10.8929C0.255156 12.1886 1.31427 13.2335 2.62758 13.2335C3.94088 13.2335 5 12.1677 5 10.8929Z"
        fill="#212121"
      />
      <path
        d="M0.255156 19.3984C0.255156 20.6911 1.31733 21.739 2.62758 21.739C3.93783 21.739 5 20.6911 5 19.3984C5 18.1058 3.93783 17.0579 2.62758 17.0579C1.31733 17.0579 0.255156 18.1058 0.255156 19.3984Z"
        fill="#212121"
      />
    </SvgIcon>
  );
};

// COMPONENTS
import { Button } from "./styles";

export const FormSubmitButton = ({ children, ...rest }) => {
  return (
    <Button color="secondary" variant="contained" {...rest}>
      {children}
    </Button>
  );
};

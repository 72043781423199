// MATERIAL
import { SvgIcon } from "@mui/material";

export const ExpandMore = ({ sx, ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 7 12" sx={{ height: 12, ...sx }} {...rest}>
      <path
        d="M0.670064 0.664787C0.443311 0.890443 0.443311 1.24674 0.670064 1.47239L5.12755 5.90829L0.664097 10.5402C0.443312 10.7658 0.449279 11.128 0.681999 11.3418C0.914719 11.5556 1.27275 11.5556 1.48757 11.324L6.33889 6.29428C6.44629 6.18145 6.5 6.03894 6.5 5.89642C6.5 5.74796 6.4463 5.60544 6.33292 5.49261L1.4816 0.664787C1.25485 0.445071 0.896817 0.445071 0.670064 0.664787Z"
        fill="#FF7E44"
      />
    </SvgIcon>
  );
};

// MATERIAL
import { Stack, InputBase, inputBaseClasses, ButtonBase } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Container = styled(Stack)({
  padding: "16px",
});

export const Input = styled(InputBase)(({ theme }) => ({
  maxWidth: 341,
  width: "100%",
  height: 40,

  borderRadius: 16,
  border: `1px solid ${theme.palette.primary.main}`,
  padding: "8.5px 14px",
  [`& .${inputBaseClasses.input}`]: {},
}));

export const Button = styled(ButtonBase)(({ theme }) => ({
  width: 115,
  height: 32,
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: theme.palette.secondary.main,
  borderRadius: 8,

  color: theme.palette.secondary.main,
  fontSize: 11,
  fontWeight: 700,

  transition: theme.transitions.create("box-shadow", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.short,
  }),

  "&:hover": {
    boxShadow: theme.shadows[1],
  },
}));

// REACT
import { useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";

// MATERIAL
import { InputBase, AccordionSummary, AccordionDetails } from "@mui/material";

// COMPONENTS
import { Accordion } from "./styles";
import { StepActions } from "../StepActions";
import { ExpandIcon } from "../../../../icons/ExpandIcon";
import { Step3 } from "../Step3";

export const Step2 = ({ item2, index1, index2, control, remove2 }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const open = Boolean(anchorEl);

  const {
    fields: fields3,
    append: append3,
    remove: remove3,
  } = useFieldArray({
    control,
    name: `steps[${index1}].steps[${index2}].steps`,
  });

  const handleOpenMenu = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (event) => {
    if (expanded) event?.stopPropagation();
    setAnchorEl(null);
  };

  const handleDeleteStep = (event) => {
    handleCloseMenu(event);
    remove2(index2);
  };

  //   const handleFocusStep = () => {
  //     handleCloseMenu();
  //     console.log("🚀 ~ input", `steps[${index1}].name`);
  //     handleSetFocus(`steps[${index1}].name`);
  //   };

  const handleAddNestedStep = (event) => {
    handleCloseMenu(event);
    append3({
      name: `Paso ${index1 + 1}.${index2 + 1}.${fields3?.length + 1}`,
      steps: [],
    });
  };

  const onInputClick = (event) => {
    event.stopPropagation();
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion
      expanded={expanded === "panel2"}
      onChange={handleChange("panel2")}
      elevation={0}
    >
      <AccordionSummary
        expandIcon={<ExpandIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Controller
          name={`steps[${index1}].steps[${index2}].name`}
          control={control}
          render={({ field }) => (
            <InputBase onClick={onInputClick} fullWidth {...field} />
          )}
        />

        <StepActions
          {...{
            handleOpenMenu,
            anchorEl,
            open,
            handleCloseMenu,
            handleDeleteStep,
            handleAddNestedStep,
            index: index2,
          }}
        />
      </AccordionSummary>
      <AccordionDetails>
        {fields3?.map((item3, index3) => (
          <Step3
            key={item3.id}
            {...{ item3, index1, index2, index3, control, remove3 }}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

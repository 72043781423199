// MATERIAL
import { SvgIcon } from "@mui/material";

export const StoreIcon = ({ sx, ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 16 16" sx={{ height: 16, ...sx }} {...rest}>
      <path
        d="M8.72976 13.6451H13.6976V9.4471H8.72976V13.6451ZM9.77131 10.4915H12.6493V12.6075H9.77131V10.4915Z"
        fill="#212121"
      />
      <path
        d="M14.7255 0H1.22655L0.0137045 2.94198V5.61775H0C0 5.69966 0.00685225 5.77474 0.0137045 5.85666V16L3.39872 15.9863L3.38501 10.5734H6.60557V15.9932H16V2.94881L14.7255 0ZM3.67966 3.56314V5.4471C3.67966 5.68601 3.60428 5.91126 3.48094 6.09556C3.1863 6.51877 2.77516 6.73037 2.25439 6.73037H2.22013C1.57602 6.71672 1.12377 6.32082 1.05525 5.75427V3.56314H3.67966ZM4.72805 5.4471C4.72805 5.44027 4.72805 5.43345 4.72805 5.42662V3.56314H7.39358V5.4471C7.39358 5.68601 7.3182 5.91126 7.19486 6.09556C6.90021 6.51195 6.48908 6.72355 5.96831 6.72355H5.93405C5.26938 6.71672 4.72805 6.17065 4.72805 5.50171V5.4471ZM8.44197 5.4471C8.44197 5.44027 8.44197 5.43345 8.44197 5.42662V3.56314H11.1143V5.43345C11.1143 5.67918 11.0458 5.91126 10.9088 6.10239C10.6141 6.51877 10.203 6.73037 9.68223 6.73037H9.64797C8.9833 6.71672 8.44197 6.17065 8.44197 5.50171V5.4471ZM12.2313 3.56314H14.9036V5.43345C14.9036 5.67918 14.8351 5.91126 14.6981 6.10239C14.4034 6.51877 13.9923 6.73037 13.4715 6.73037H13.4373C12.7726 6.71672 12.2313 6.17065 12.2313 5.50171V3.56314ZM14.0403 1.04437L14.6775 2.5256H1.31563L1.92548 1.04437H14.0403ZM7.64711 9.52901H2.33662L2.35032 14.9488L1.05525 14.9556V7.46075C1.38415 7.65188 1.77473 7.76109 2.19957 7.76792H2.25439C3.2 7.76792 3.803 7.33788 4.17302 6.90102C4.58415 7.41979 5.20771 7.75427 5.91349 7.76792H5.96831C6.73576 7.76792 7.40728 7.46075 7.88694 6.90102C8.29807 7.41979 8.92163 7.75427 9.62741 7.76792H9.68223C10.4702 7.76792 11.1555 7.44027 11.642 6.85324C12.0531 7.39932 12.6972 7.76109 13.4236 7.76792H13.4784C14.0334 7.76792 14.5336 7.60409 14.9516 7.31058V14.9556H7.64711V9.52901Z"
        fill="#212121"
      />
    </SvgIcon>
  );
};

// MATERIAL
import { SvgIcon } from "@mui/material";

export const SearchIcon = ({ sx, ...rest }) => {
  return (
    <SvgIcon viewBox="0 0 14 14" sx={{ height: 14, ...sx }} {...rest}>
      <path
        d="M13.8712 13.2245L9.87375 9.207C10.7107 8.23324 11.2199 6.96793 11.2199 5.58601C11.214 2.50729 8.69733 0 5.60702 0C2.51672 0 0 2.50729 0 5.58601C0 8.66472 2.51672 11.172 5.60702 11.172C6.98244 11.172 8.24666 10.6764 9.22408 9.8484L13.2216 13.8601C13.3094 13.9475 13.4264 13.9942 13.5435 13.9942C13.6605 13.9942 13.7776 13.9475 13.8654 13.8601C14.0468 13.691 14.0468 13.4052 13.8712 13.2245ZM0.913044 5.58601C0.913044 3.00875 3.02007 0.909621 5.60702 0.909621C8.19398 0.909621 10.301 3.00875 10.301 5.58601C10.301 8.16327 8.19398 10.2624 5.60702 10.2624C3.02007 10.2624 0.913044 8.16327 0.913044 5.58601Z"
        fill="#212121"
      />
    </SvgIcon>
  );
};

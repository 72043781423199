// MATERIAL
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

export const ConfirmDialog = ({
  isOpen,
  handleDismiss,
  handleAgree,
  title,
  content,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleDismiss}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleDismiss}>
          No
        </Button>
        <Button
          variant="contained"
          color="warning"
          onClick={handleAgree}
          autoFocus
        >
          Si
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { useState } from "react";
import { Outlet } from "react-router-dom";

// COMPONENTS
import { Drawer } from "../Drawer";
import { Header } from "../Header";
import { Container, Main } from "./styles";

export const Navigation = () => {
  const [open, setOpen] = useState(true);
  const drawerWidth = open ? 266 : 66;

  const handleToggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <Header />
      <Drawer {...{ open, handleToggleDrawer, drawerWidth }} />
      <Main>
        <Container>
          <Outlet />
        </Container>
      </Main>
    </>
  );
};

// REACT
import { Controller } from "react-hook-form";

// MATERIAL
import { FormControlLabel } from "@mui/material";
import { StyledSwitch } from "./styles";

export const SwitchButton = ({ control, name, label }) => (
  <Controller
    name={name}
    control={control}
    rules={{ required: false }}
    render={({
      field: { onChange, onBlur, value, ref },
      fieldState: { invalid, isTouched, isDirty, error },
    }) => (
      <FormControlLabel
        control={
          <StyledSwitch
            color="secondary"
            checked={value}
            onChange={onChange}
            inputRef={ref}
            onBlur={onBlur}
          />
        }
        label={label}
      />
    )}
  />
);

// MATERIAL
import {
  Accordion as MuiAccordion,
  accordionClasses,
  accordionSummaryClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const Accordion = styled(MuiAccordion)(({ theme }) => ({
  overflow: "hidden",
  backgroundColor: "transparent",
  transition: "all 300ms ease",

  [`&.${accordionClasses.expanded}`]: {
    borderRadius: 15,

    [`& > .${accordionSummaryClasses.root}`]: {
      borderRadius: 15,
    },
  },

  [`& > .${accordionSummaryClasses.root}`]: {
    backgroundColor: theme.palette.lightSky.main,
    borderRadius: 0,
    padding: `0 ${theme.spacing(1)}`,

    [`&.${accordionSummaryClasses.focusVisible}`]: {
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: 15,
    },
  },

  "&:first-of-type": {
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
  },
  "&:last-of-type": {
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
  },
}));

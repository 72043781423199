// MATERIAL
import {
  Drawer as MuiDrawer,
  Box,
  ButtonBase,
  typographyClasses,
  buttonBaseClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const AppDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "drawerWidth",
})(({ theme, open, drawerWidth }) => ({
  position: "relative",
  width: drawerWidth,
  boxShadow: theme.shadows[1],
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),

  "& .MuiDrawer-paper": {
    whiteSpace: "nowrap",
    overflowX: "hidden",
    boxSizing: "border-box",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    ...(!open && {
      overflowX: "hidden",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }),

    [`& div.${buttonBaseClasses.root}`]: {
      [`& .${typographyClasses.root}`]: {
        fontWeight: 900,
      },
    },

    [`& a.${buttonBaseClasses.root}`]: {
      [`& .${typographyClasses.root}`]: {
        fontSize: 12,
        paddingLeft: 79,
      },
      "&.active": {
        [`& .${typographyClasses.root}`]: {
          color: theme.palette.primary.light,
        },
      },
    },

    ...(open && {
      [`& div.${buttonBaseClasses.root}`]: {
        paddingLeft: 40,
        paddingRight: 32,
        [`& .${typographyClasses.root}`]: {
          fontWeight: 900,
        },
      },
    }),
  },
}));

export const Header = styled(Box)({
  height: 116,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "0 7px",
});

export const DrawerToggleButton = styled(ButtonBase, {
  shouldForwardProp: (props) => props !== "open",
})(({ theme, open }) => ({
  height: 24,
  width: 24,
  borderRadius: 12,
  backgroundColor: theme.palette.primary.main,
  boxShadow: theme.shadows[2],
  position: "absolute",
  right: 0,
  top: 81,
  "& .MuiSvgIcon-root": {
    ...(!open && {
      transform: "rotate(180deg)",
    }),
  },
}));

export const Img = styled("img")({
  width: "100%",
  maxWidth: 54,
  height: "auto",
});

// MATERIAL
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const HighlightBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "centered" && "padding",
})(({ theme, centered, padding }) => ({
  backgroundColor: theme.palette.lightSky.main,
  borderRadius: 15,
  ...(centered && {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
  ...(padding && {
    padding: padding,
  }),
}));

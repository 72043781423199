// MATERIAL
import { Paper as MuiPaper } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Paper = styled(MuiPaper, {
  shouldForwardProp: (prop) => prop !== "noPadding",
})(({ theme, noPadding }) => ({
  width: "100%",
  borderRadius: 15,
  ...(!noPadding && {
    padding: 16,
  }),
}));
